import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import axios from 'axios'
import store from './store'
import './plugins/base'
import ImageLoader from './components/Common/imageLoader'
Vue.component('ImageLoader', ImageLoader)

import VueElementLoading from 'vue-element-loading'
Vue.component('VueElementLoading', VueElementLoading)
import OwlCarousel from 'vue-owl-carousel'
Vue.component('OwlCarousel', OwlCarousel)

import Vue2Editor from "vue2-editor"; // ✅ Import vue2-editor

Vue.use(Vue2Editor); // ✅ Register globally

// import Viewer from 'v-viewer'
// Vue.use(Viewer)
// import 'viewerjs/dist/viewer.css'
import ServerError from './components/Common/500'
Vue.component('ServerError', ServerError)

window.axios = require('axios')
// window.axios = require('axios')
// axios.defaults.baseURL = 'http://192.168.45.58:5050'
// Vue.prototype.baseURL = "http://192.168.45.58:5050"
// Vue.prototype.mediaURL = "http://192.168.45.58:5050/file/get/"
// Vue.prototype.mediaURLnew = "http://192.168.45.58:5050/wp"


window.axios = require('axios')
axios.defaults.baseURL = 'https://api.teamite.app/'
Vue.prototype.baseURL = "https://api.teamite.app/"
Vue.prototype.mediaURL = "https://api.teamite.app/file/get/"
Vue.prototype.mediaURLnewx = "https://api.teamite.app/u/"

// window.axios = require('axios')
// axios.defaults.baseURL = 'http://localhost:5050'
// Vue.prototype.baseURL = "http://localhost:5050"
// Vue.prototype.mediaURL = "http://localhost:5050/file/get/"
// Vue.prototype.mediaURLnew = "http://localhost:5050/wp/"

axios.defaults.timeout = 60000;



Vue.config.productionTip = false

new Vue({
    vuetify,
    store,
    router,

    render: h => h(App)
}).$mount('#app')